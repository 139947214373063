import React from "react";

export default function Button({
  label,
  onclick=null,
  style: overriddenStyles = {},
  className: overriddenClasses = "",
}) {
  return (
    <>
      <button
        style={{
          ...overriddenStyles,
          display: "flex",
          outline: "none",
        }}
        className={overriddenClasses}
        onClick={onclick && onclick}
      >
        {label}
      </button>
    </>
  );
}
