import React from "react";
import recipesCategory from "../../../Assets/Data/samayal/homeScreen.json";
import { SAMAYAL_URL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import Image from "../../../component/core/image";

/***
 * {
 * recipe: [],
 * nonveg: [],
 * festivals: [],
 * ...
 * }
 */

export default function HomeMain({}) {
  const navigate = useNavigate();

  const ListComponent = ({ recipe = {}, onClick = null }) => (
    <div className="mx-1">
    <button
      className="mt-2 xs:mt-1 xs:p-1 hover:opacity-75"
      onClick={() => onClick && onClick(recipe)}
    >
      <Image
        className="rounded-lg w-[300px] h-[150px]"
        src={`${SAMAYAL_URL}${recipe.imgName}`}
      />
      {recipe.title}
    </button></div>
  );

  return [
    {
      recipiesList: recipesCategory.recipe,
      title: null,
    },
    {
      recipiesList: recipesCategory.festivals,
      title: "பண்டிகை உணவுகள்",
    },
    {
      recipiesList: recipesCategory.nonVeg,
      title: "அசைவ உணவுகள்",
    },
  ].map((recipies, recipeIndex) => (
    <div
      key={recipeIndex}
      className={`${recipeIndex % 2 !== 0 ? `bg-[#c1f4c5]` : ""}`}
    >
      {recipies.title && (
        <p className="text-center text-2xl">{recipies.title}</p>
      )}
      <div className="grid lg:grid-cols-4 gap-1 sm:grid-cols-3 grid-cols-2 text-center text-lg">
        {recipies.recipiesList.map((recipe, index) => (
          <ListComponent
            index={index}
            recipe={recipe}
            onClick={(recipe) => {
              navigate("/samayal/categories", {
                state: { name: recipe.name, values: recipe.values },
              });
            }}
          />
        ))}
      </div>
    </div>
  ));
}
