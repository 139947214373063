import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HorizontalLine from "../../../component/core/horizontalLine";
import Image from "../../../component/core/image";
import GetDate from "../../../component/widgets/getDate";
import { ARTICLE_URL } from "../../../constants";

export default function TrendingMain() {
  const [trends, setTrends] = useState([]);

  useEffect(() => {
    axios.get("/articles/trends").then((res) => {
      setTrends(res.data.articles);
    });
  }, []);
  // console.log(trends);
  return (
    <>
      {trends.map((trend) => {
        return (
          <div key={trend.id}>
            <Link to={`/articles/articles-share-id=/${trend.id}`}>
              <p className="m-3 font-bold">{trend.title}</p>
              <div className="flex ml-3">
                <GetDate date={trend.createdAt} />
                <p className="ml-5">{trend.viewed} Views</p>
              </div>
              <Image
                src={`${ARTICLE_URL}${trend.banner_url}`}
                className="w-[300px] h-[200px] m-3"
              />
              <p>{trend.short_desc}</p>
            </Link>
            <HorizontalLine />
          </div>
        );
      })}
    </>
  );
}
