import Button from "../core/button";
import { ButtonWithImage } from "./buttonWithImage";
import { SAMAYAL_URL } from "../../constants";
import Image from "../core/image";
import Like from "../../images/icons/likeIcon.png";
import React from "react";
import Share from "../../images/icons/shareIcon.png";
import { useNavigate, Link } from "react-router-dom";

export default function SamayalCard({ title, recipeID }) {
  const navigate = useNavigate();

  return (
    <div className="relative bg-secondary border rounded-md mt-4 ml-2">
      <p className="text-center mt-3">{title}</p>
      <Image
        className="my-3 w-[100%] h-[150px]"
        src={`${SAMAYAL_URL}${recipeID}c.png`}
      />
      <div className="flex mb-3">
        <ButtonWithImage className="xs:w-6 w-5 ml-3" src={Like} />
        <ButtonWithImage className="xs:w-5 w-4 ml-3" src={Share} />
        <Button
          label={"குறிப்பு"}
          className="border ml-3 hover:opacity-75"
          onclick={() =>
            navigate(`/samayal/view`, {
              state: { id: recipeID },
            })
          }
        />
      </div>
    </div>
  );
}
