import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HorizontalLine from "../../../component/core/horizontalLine";
import Image from "../../../component/core/image";
import { ARTICLE_URL } from "../../../constants";

export default function LatestMain() {
  const [latest, setLatest] = useState([]);

  useEffect(() => {
    axios
      .post("/articles/latest", { paging: { offset: 0, limit: 10 } })
      .then((response) => {
        setLatest(response.data.articles);
      });
  }, []);
  // console.log(latest);
  return (
    <div>
      {latest.map((latest) => {
        return (
          <>
            <Link to={`/articles/articles-share-id=/${latest.id}`}>
              <abbr title="Latest" className="no-underline"><p>{latest.title}</p></abbr>
              <Image src={`${ARTICLE_URL}${latest.banner_url}`} />
            </Link>
            <HorizontalLine/>
          </>
        );
      })}
    </div>
  );
}
