import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import WeatherData from "../../Assets/Data/weather_data.json";

export default function Weather() {
  const [weather, setWeather] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?id=1264527&APPID=b877d8a01f74c37c732c644d8876cab9&units=metric"
      )
      .then((res) => {
        setWeather(res.data);
      });
  }, []);

  function fun({ base, name, main }) {
    return (
      <>
        <p>
          Base Name is {base} and the city is {name}
        </p>
        {main}
      </>
    );
  }
  console.log(WeatherData);
  return (  
    <div>
      {fun(weather)}
      {weather.map((data) => {
        return (
          <>
            {data.main.map((dat) => {
              return <>{dat.temp}</>;
            })}
          </>
        );
      })}
      {/* {Object.keys(weather).map((obj) => {
        return <>{weather[obj].base}</>;
      })} */}
      working
    </div>
  );
}
