import React from "react";
import { NavLink } from "react-router-dom";
export default function ArticlesNavBar() {
  const activeLinkStyle = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
    };
  };
  return (
    <div className="">
      <NavLink
        className="ml-10"
        style={activeLinkStyle}
        to="/articles/home-main"
      >
        Home
      </NavLink>

      <NavLink
        className="xs:ml-10 ml-2"
        style={activeLinkStyle}
        to="/articles/list"
      >
        New
      </NavLink>

      <NavLink
        className="xs:ml-10 ml-2"
        style={activeLinkStyle}
        to="/articles/trend"
      >
        Trending
      </NavLink>

      <NavLink
        className="xs:ml-10 ml-2"
        style={activeLinkStyle}
        to="/articles/category"
      >
        Categories
      </NavLink>
      <NavLink
        className="xs:ml-10 ml-2"
        style={activeLinkStyle}
        to="/articles/fav"
      >
        Favourite
      </NavLink>
    </div>
  );
}
