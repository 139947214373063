import React from "react";
import { useNavigate } from "react-router-dom";
import homeData from "../../../Assets/Data/samayal/homeScreenMobile.json";
import Button from "../../../component/core/button";
import { ButtonWithImage } from "../../../component/widgets/buttonWithImage";
import { ImageWithTitle } from "../../../component/widgets/imageWithTitle";
import { SAMAYAL_URL } from "../../../constants";

export function HomeMainMobile() {
  
  const navigate = useNavigate();
 
  // const ket = (homeData, key) =>
  //   homeData[key] !== undefined;
  //   for (var i = 1; i <= 10; i++) {
  //     let key = `recipe${i}Id`;
  //     if (ket(homeData, key) === true) {
  //       switch (i) {
  //         case 1:
  //           console.log(homeData.recipe1Id);
  //         case 2:
  //           console.log(homeData.recipe2Id);
  //           break;
  //         case 3:
  //           console.log(homeData.recipe3Id);
  //           break;
  //         case 4:
  //           console.log(homeData.recipe4Id);
  //           break;
  //         default:
  //           null;
  //       }
  //     }

  // };
  return (
    <>
      {/* <p>
        {Data.map((item) => {
          
          return <p></p>;
        })}
      </p> */}
      {homeData.fragments.map((data) => {
        switch (data.type) {
          case 1:
            return (
              <ButtonWithImage
                src={`${SAMAYAL_URL}${data.coverImageURL}`}
                onclick={() => {
                  navigate("/samayal/categories", {
                    state: { name: "places", values: "3" },
                  });
                }}
              />
            );
          case 2:
            switch (data.category1Id) {
              case 52:
                return (
                  <>
                    <ButtonWithImage
                      className="absolute m-5  w-1/2 right-0"
                      src={`${SAMAYAL_URL}${data.recipe1Id}c.png`}
                      onclick={() =>
                        navigate("/samayal/view", {
                          state: { id: data.recipe1Id },
                        })
                      }
                    />
                    <ButtonWithImage
                      src={`${SAMAYAL_URL}${data.coverImageURL}`}
                      onclick={() => {
                        navigate("/samayal/categories", {
                          state: { name: "food", values: "2" },
                        });
                      }}
                    />
                  </>
                );
              case 53:
                return (
                  <>
                    <ButtonWithImage
                      className="absolute m-5  w-1/2 right-0"
                      src={`${SAMAYAL_URL}${data.recipe1Id}c.png`}
                      onclick={() =>
                        navigate("/samayal/view", {
                          state: { id: data.recipe1Id },
                        })
                      }
                    />
                    <ButtonWithImage
                      src={`${SAMAYAL_URL}${data.coverImageURL}`}
                      onclick={() => {
                        navigate("/samayal/categories", {
                          state: { name: "tastes", values: "1" },
                        });
                      }}
                    />
                  </>
                );
              case 111:
                return (
                  <>
                    <ButtonWithImage
                      className="absolute m-5  w-1/2 right-0"
                      src={`${SAMAYAL_URL}${data.recipe1Id}c.png`}
                      onclick={() =>
                        navigate("/samayal/view", {
                          state: { id: data.recipe1Id },
                        })
                      }
                    />
                    <ButtonWithImage
                      src={`${SAMAYAL_URL}${data.coverImageURL}`}
                      onclick={() => {
                        navigate("/samayal/categories", {
                          state: { name: "festivals", values: "8" },
                        });
                      }}
                    />
                  </>
                );
            }
          case 3:
            return (
              <>
                <div className="grid grid-cols-2 mt-2 border-t border-gray-400 ">
                  <div className="m-3">
                    <p>{data.title}</p>
                    <p>{data.countText}</p>
                  </div>
                  <Button
                    label={"மேலும்"}
                    className="my-3 xs:ml-28 ml-16"
                    onclick={() => {
                      navigate("/samayal/categories", {
                        state: { name: "food", values: "1" },
                      });
                    }}
                  />
                </div>
                <div className="grid grid-cols-3 gap-1">
                  <ImageWithTitle
                    className="w-90 h-28"
                    label={"data"}
                    src={`${SAMAYAL_URL}${data.recipe1Id}c.png`}
                    onclick={() => {
                      navigate("/samayal/view", {
                        state: { id: data.recipe1Id },
                      });
                    }}
                  />
                  <ImageWithTitle
                    className="w-90 h-28"
                    src={`${SAMAYAL_URL}${data.recipe2Id}c.png`}
                    onclick={() => {
                      navigate("/samayal/view", {
                        state: { id: data.recipe2Id },
                      });
                    }}
                  />
                  <ImageWithTitle
                    className="w-90 h-28"
                    src={`${SAMAYAL_URL}${data.recipe3Id}c.png`}
                    onclick={() => {
                      navigate("/samayal/view", {
                        state: { id: data.recipe3Id },
                      });
                    }}
                  />
                </div>
              </>
            );
          case 4:
            switch (data.category1Id) {
              case 56:
                return (
                  <div className="my-2 grid grid-cols-2 border-t border-gray-400">
                    <p className="m-3">
                      {data.title}
                      <p>{data.countText}</p>
                    </p>
                    <Button
                      label={"மேலும்"}
                      className="my-3 xs:ml-36 ml-16"
                      onclick={() => {
                        navigate("/samayal/categories", {
                          state: { name: "food", values: "6" },
                        });
                      }}
                    />
                  </div>
                );
              case 58:
                return (
                  <div className="my-2 grid grid-cols-2 border-t border-gray-400">
                    <p className="m-3">
                      {data.title}
                      <p>{data.countText}</p>
                    </p>
                    <Button
                      label={"மேலும்"}
                      className="my-3 xs:ml-36 ml-16"
                      onclick={() => {
                        navigate("/samayal/categories", {
                          state: { name: "food", values: "8" },
                        });
                      }}
                    />
                  </div>
                );
            }
          case 5:
            return (
              <>
                <div className="grid grid-cols-2 h-14 text-center">
                  <Button
                    style={{ background: data.category1Color }}
                    className={"m-1 p-3"}
                    label={data.categoryTitle1}
                    onclick={() => {
                      navigate("/samayal/categories", {
                        state: { name: "food", values: "8" },
                      });
                    }}
                    // to="/list-View/Veg"
                  />
                  <Button
                    style={{ background: data.category2Color }}
                    className={"m-1 p-3"}
                    label={data.categoryTitle2}
                    onclick={() => {
                      navigate("/samayal/categories", {
                        state: { name: "food", values: "8" },
                      });
                    }}
                    //to="/list-View/:nonVeg"
                  />
                </div>
                <div className="grid grid-cols-2 h-14 text-center">
                  <Button
                    style={{ background: data.category3Color }}
                    className={"m-1"}
                    label={data.categoryTitle3}
                    onclick={() => {
                      navigate("/samayal/categories", {
                        state: { name: "food", values: "2" },
                      });
                    }}
                    //to="/list-View/food/2"
                  />
                  <Button
                    style={{ background: data.category4Color }}
                    className={"m-1 p-3"}
                    label={data.categoryTitle4}
                    onclick={() => {
                      navigate("/samayal/categories", {
                        state: { name: "food", values: "8" },
                      });
                    }}
                  />
                </div>
              </>
            );
          case 6:
            return (
              <div className="my-2">
                <div className="grid grid-cols-4 border-t border-gray-400">
                  <p className="text-xl mx-2 mt-3 col-span-3">
                    {data.title}
                    <p>{data.countText}</p>
                  </p>
                  <button to="/list-View/food/1" className="my-4">
                    மேலும்
                  </button>
                </div>
                <p className="m-3 justify-evenly">{data.tips}</p>
                {/* <div className="grid grid-cols-3 gap-2 my-7">
                  <button to={`/detail-view/${data.recipe1Id}`}>
                    <Image
                      className="w-full h-28"
                      src={`${SAMAYAL_URL}${data.recipe1Id}c.png`}
                      
                    />
                  </button>
                  <button to={`/detail-view/${data.recipe2Id}`}>
                    <Image
                      className="w-full h-28"
                      src={`${SAMAYAL_URL}${data.recipe2Id}c.png`}
                      alt="recipeimage"
                    />
                  </button>
                  <button to={`/detail-view/${data.recipe3Id}`}>
                    <Image
                      className="w-full h-28"
                      src={`${SAMAYAL_URL}${data.recipe3Id}c.png`}
                      alt="recipeimage"
                    />
                  </button>
                </div>
                <div className="grid grid-cols-3 gap-2 my-7">
                  <button to="/">
                    <Image
                      className="w-full h-28"
                      src={`${SAMAYAL_URL}${data.recipe4Id}c.png`}
                      alt="recipeimage"
                    />
                  </button>
                  <button to="/">
                    <Image
                      className="w-full h-28"
                      src={`${SAMAYAL_URL}${data.recipe5Id}c.png`}
                      alt="recipeimage"
                    />
                  </button>
                  <button to="/">
                    <Image
                      className="w-full h-28"
                      src={`${SAMAYAL_URL}${data.recipe6Id}c.png`}
                      alt="recipeimage"
                    />
                  </button>
                </div> */}
              </div>
            );
          default:
            return null;
        }
             })}
    </>
  );
}
