import React from "react";
import { current } from "../../constants";

export default function MonthlyCalendar() {
  let firstDayOfMonth = new Date(current.getFullYear(), current.getMonth(), 1);
  let weekdayOfFirstDay = firstDayOfMonth.getDay();
  let currentDays = [];
  const weekDays = [
    "ஞாயிறு",
    "திங்கள்",
    "செவ்",
    "புதன்",
    "வியா",
    "வெள்",
    "சனி",
  ];
  const months = [
    "ஜனவரி",
    "பிப்ரவரி",
    "மார்ச்",
    "ஏப்ரல்",
    "மே",
    "ஜூன்",
    "ஜூலை",
    "ஆகஸ்ட்",
    "செப்டம்பர்",
    "அக்டோபர்",
    "நவம்பர்",
    "டிசம்பர்",
  ];
  for (let day = 0; day < 42; day++) {
    if (day === 0 && weekdayOfFirstDay === 0) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if (day === 0) {
      firstDayOfMonth.setDate(
        firstDayOfMonth.getDate() + (day - weekdayOfFirstDay)
      );
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }

    let calendarDay = {
      currentMonth: firstDayOfMonth.getMonth() === current.getMonth(),
      date: new Date(firstDayOfMonth),
      month: firstDayOfMonth.getMonth(),
      number: firstDayOfMonth.getDate(),
      selected: firstDayOfMonth.toDateString() === current.toDateString(),
      year: firstDayOfMonth.getFullYear(),
    };
    currentDays.push(calendarDay);
  }

  return (
    <div className=" flex w-[400px] h-[390px] flex-col mt-3 ml-1 text-center">
      <div className=" flex flex-col grow-0 w-[100%]">
        <div className="bg-[#EADCBF] flex center w-[140%] h-[35px] justify-between">
          {weekDays.map((weekday) => {
            return <div className="lg:w-[80px] text-center">{weekday}</div>;
          })}
        </div>
        <div className="bg-secondary w-[140%] grow-0 flex-wrap flex">
          {currentDays.map((day) => {
            return (
              <div
                className={`h-[50px] w-[80px] relative  border-solid
                  ${day.selected ? "bg-[#EADCBF]" : ""}`}
              >
                <p
                  className={`absolute right-[40px] mt-2  ${
                    day.currentMonth ? "text-[#000000]" : "text-[#a6a6a6]"
                  }`}
                >
                  {day.number}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
