import axios from "axios";
import React, { useEffect, useState, Redirect } from "react";
import { Route, Routes } from "react-router-dom";
import ArticlesNavBar from "./articlesNavBar";
import HomeMain from "./home/homeMain";
import CategoryMain from "./list/categoryMain";
import Favourites from "./list/favouritesMain";
import LatestMain from "./list/latestMain";
import TrendingMain from "./list/trendingMain";
import ViewMain from "./view/viewMain";

/**
 * Handle subroutings modules specific,
 * eg: Articles -> Home, List,..
 */
export default function ArticlesMain() {
  const [posts, setPosts] = useState([]);
  const getData = () => {
    axios.get("/articles/home").then((res) => setPosts(res.data));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ArticlesNavBar />
      <Routes>
        <Route path="/home-main" element={<HomeMain />} />
        <Route path="/list" element={<LatestMain />} />
        <Route path="/articles-share-id=/:id" element={<ViewMain />} />
        <Route path="/view" element={<ViewMain />} />
        <Route path="/fav" element={<Favourites />} />
        <Route path="/category" element={<CategoryMain/>} />
        <Route path="/trend" element={<TrendingMain/>} />
        <Route path="/*" element={<HomeMain/>} />
        
      </Routes>
    </>
  );
}
