// import axios from "axios";
import React, { useEffect } from "react";
import RasiPalan from "../Assets/Data/day-12-2022.json";
import DailyRasipalan from "./calendar/rasi_palan/dailyRasipalan";
import LatestMain from "../pages/articles/list/latestMain";
import OurApps from "../pages/ourApps";
import RasiKattam from "./calendar/rasi_palan/rasiKattam";
import { current } from "../constants";
import MonthlyCalendar from "./calendar/monthlyCalendar";
export default function HomeMain() {
  const url = "/calendar/tamil/rasi/daily_rasi_10_2022.json";

  const title = [
    "நல்ல நேரம்(காலை)",
    "நல்ல நேரம்(மாலை)",
    "கௌரி நல்ல நேரம்(காலை)",
    "கௌரி நல்ல நேரம்(மாலை)",
    "நட்சத்திரம்",
    "ராகு",
    "குளிகை",
    "எமகண்டம்",
    "கரணம்",
    "சூரிய உதயம்",
    "சூலம்",
    "பரிகாரம்",
  ];
  // ,, , ,
  const days = [
    "ஞாயிறு",
    "திங்கள்",
    "செவ்வாய்",
    "புதன்",
    "வியாழன்",
    "வெள்ளி",
    "சனி",
  ];
  const ListComponent = ({ title, description }) => {
    return (
      <div className="border rounded-lg flex w-[280px] mb-3 p-3 bg-secondary">
        <p>{title}</p>-<p>{description}</p>
      </div>
    );
  };

  const months = [
    "ஜனவரி",
    "பிப்ரவரி",
    "மார்ச்",
    "ஏப்ரல்",
    "மே",
    "ஜூன்",
    "ஜூலை",
    "ஆகஸ்ட்",
    "செப்டம்பர்",
    "அக்டோபர்",
    "நவம்பர்",
    "டிசம்பர்",
  ];
  return (
    <>
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-1">
        <div className="border mt-4 ml-2 mb-2 p-2 w-[300px] h-auto bg-primary rounded-lg">
          <p className="mb-2 text-center text-white">
            {current.getDate()}-{months[current.getMonth()]}-
            {current.getFullYear()}
          </p>
          {RasiPalan.map((rasi) =>
            rasi.enDate == current.getDate() ? (
              <>
                <ListComponent
                  title={"நல்ல நேரம்(காலை)"}
                  description={rasi.nallaNeram[0]}
                />
                <ListComponent
                  title={"நல்ல நேரம்(மாலை)"}
                  description={rasi.nallaNeram[1]}
                />
                <ListComponent
                  title={"கௌரி நல்ல நேரம்(காலை)"}
                  description={rasi.gowriNallaNeram[0]}
                />
                <ListComponent
                  title={"கௌரி நல்ல நேரம்(மாலை)"}
                  description={rasi.gowriNallaNeram[1]}
                />
                <ListComponent title={"நட்சத்திரம்"} description={rasi.star} />
              </>
            ) : null
          )}
        </div>
        <div className="col-span-2">
          <div className=" bg-primary ml-8 rounded-lg lg:w-[570px] h-[380px] mt-7">
            <p className="ml-5 text-white">
              {months[current.getMonth()]} {`${current.getFullYear()}`}
              {/* {days[current.getDay()]} */}
            </p>
            <MonthlyCalendar/>
          </div>
          <RasiKattam />
        </div>
        <div>
          <DailyRasipalan />
        </div>
      </div>
      <div className="m-5 w-[30%] h-[10%] overflow-hidden">
        <p className="ml-3 font-bold">Articles</p>
        <LatestMain />
      </div>
      <div className="m-5 overflow-hidden">
        <OurApps />
      </div>
    </>
  );
}
