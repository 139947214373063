import React from "react";
import DailyRasipalan from "./dailyRasipalan";

export default function RasiPalanMain(props) {
  return (
    <div>
      <DailyRasipalan />
    </div>
  );
}
