import React from "react";
import { ButtonWithImage } from "../widgets/buttonWithImage";

export default function ChangeImage({
  src1,
  src2,
  className: overriddenClasses = "",
  style: overriddenStyles = {},
  onclick,
}) {
  function handler(e) {
    const src = e.target.getAttribute("src");
    src == src1
      ? e.target.setAttribute("src", src2)
      : e.target.setAttribute("src", src1);

    // if (src == src1) {
    //   e.target.setAttribute("src", src2);
    // } else {
    //   e.target.setAttribute("src", src1);
    // }
  }

  return (
    <>
      <div onClick={onclick}>
        <ButtonWithImage
          className={overriddenClasses}
          style={{
            ...overriddenStyles,
          }}
          src={src1}
          onclick={handler}
        />
      </div>
    </>
  );
}
