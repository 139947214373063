import React from "react";
import panjangam from "../../../Assets/Data/panchangam.json";
import { current } from "../../../constants";

export default function RasiKattam() {
  let date = [current.getDate(), current.getMonth(), current.getFullYear()];

  function RasikattamList({ rasi, transition, startDate, endDate }) {
    //
    const fromDate = new Date(
      startDate[2],
      parseInt(startDate[1]) - 1,
      startDate[0]
    );
    const toDate = new Date(endDate[2], parseInt(endDate[1]) - 1, endDate[0]);
    const currentDate = new Date(date[2], parseInt(date[1]), date[0]);
    return (
      <>
        {currentDate >= fromDate && currentDate <= toDate ? (
          <div className=" bg-primary sm:w-[550px] h-[200px] mt-5 sm:ml-[40px] m-4">
            <p className="text-center text-white">ராசிகட்டம்</p>
            <div className="bg-secondary m-1 w-[540px] h-[180px] rounded-sm">
              <div className="grid grid-cols-4 grid-rows-4 text-center">
                <div className="border">{rasi[11]}</div>
                <div className="border">{rasi[0]}</div>
                <div className="border">{rasi[1]}</div>
                <div className="border"> {rasi[2]}</div>
                <div className="border">{rasi[10]} </div>
                <div className="border col-span-2 row-span-2">
                  <p className="mt-6">{transition} </p>
                </div>
                <div className="border"> {rasi[3]} </div>
                <div className="border">{rasi[9]} </div>
                <div className="border">{rasi[4]}</div>
                <div className="border">{rasi[8]} </div>
                <div className="border">{rasi[7]} </div>
                <div className="border"> {rasi[6]} </div>
                <div className="border">{rasi[5]} </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }

  return (
    <>
      {panjangam.map((data) => {
        return (
          <>
            <RasikattamList
              rasi={data.rasi}
              transition={data.transition}
              startDate={[
                data.enStartDate,
                data.enStartMonth,
                data.enStartYear,
              ]}
              endDate={[data.enEndDate, data.enEndMonth, data.enEndYear]}
            />
          </>
        );
      })}
    </>
  );
}
