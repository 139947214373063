import React from "react";
import { useState } from "react";

export default function ToolTip({ title, className, children }) {

  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className="absolute bg-black ml-5 mt-1 p-[5px] text-white" >{title} </div>
      )}
    </div>
  );
}
