import React from "react";
import { SAMAYAL_URL } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { ImageWithTitle } from "../../../component/widgets/imageWithTitle";

export function GetSuggestedRecipes(Data, recipes) {
  const navigate = useNavigate();

  //fetch the suggestedrecipes according to the recipe clicked.

  const getRecipe = (id) => {
    return Data.filter((samayalData) => samayalData.recipe_id == id);
  };
  const recipe = [];
  recipes.forEach((id) => {
    recipe.push(getRecipe(id));
  });

  return (
    <>
      {recipe.map((item) => {
        return (
          <div
            className="mt-4 border rounded w-35 hover:opacity-75 float-none"
            key={item.recipe_id}
          >
            <ImageWithTitle
              src={`${SAMAYAL_URL}${item[0].recipe_id}c.png`}
              label={item[0].title}
              onclick={() =>
                navigate(`/samayal/view`, { state: { id: item[0].recipe_id } })
              }
            />
          </div>
        );
      })}
    </>
  );
}
