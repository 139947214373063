import React from "react";
import Image from "../core/image";

export function ImageWithTitle({
  label = "title",
  src,
  onclick,
  style: overriddenStyles = {},
  className: overriddenClasses = "",
}) {
  return (
    <div
      style={{
        ...overriddenStyles,
        
      }}
      className={overriddenClasses}
      onClick={onclick}
    >
      <Image src={src} />
      <p style={{ ...overriddenStyles,"marginTop":"10px",textAlign:"center" }}>{label && label}</p>
    </div>
  );
}
