import React from "react";
import Image from "../../../component/core/image";
import { Link } from "react-router-dom";
import GetDate from "../../../component/widgets/getDate";
import HorizontalLine from "../../../component/core/horizontalLine";
import { ARTICLE_URL } from "../../../constants";
export default function RelatedArticle({ props }) {
  return (
    <>
      {props.map((index) => {
        return (
          <div key={index.id}>
            <Link to={`/articles/articles-share-id=/${index.id}`}>
              <div className="mx-2">
                <Image
                  className="w-[400px] h-[200px]"
                  src={`${ARTICLE_URL}${index.thumbnail_url}`}
                />
                <p className="mt-1">{index.title}</p>
                <GetDate date={index.date_of_publish} />
              </div>
            </Link>
            <HorizontalLine />
          </div>
        );
      })}
    </>
  );
}
