import React from "react";
// import panjagam from "../Assets/Data/panchangam.json";

export default function PanchangamMain() {
  const current = new Date();
  const date = `${current.getDate()}`;
  return (
    <div>
    </div>
  );
}
