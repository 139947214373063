import React from "react";
import SamayalCard from "../../../component/widgets/samayalCard";
import { useLocation } from "react-router-dom";



export default function CategoriesMain({props}) {
  const locate = useLocation();
  const name = locate.state.name;
  const values = locate.state.values;

  
  function findByCatogory() {
    return props.filter((items) =>
      items["categories"][name].some((items) => items == values)
    );
  }
  return (
    <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-1 ">

      {findByCatogory().map((recipe) => (
        <div key={recipe.recipe_id}>
          <SamayalCard title={recipe.title} recipeID={recipe.recipe_id} />
        </div>
      ))}
    </div>
  );
}
