import ARTICLEICON from "../../images/icons/articleIcon.png";
import BOOKSICON from "../../images/icons/booksIcon.png";
import TNPSCICON from "../../images/icons/tnpscIcon.png";
import SAMAYALICON from "../../images/icons/samayalIcon.png";
import CALENDARICON from "../../images/icons/calendarIcon.png";
import { ButtonWithImage } from "./buttonWithImage";
import HOMEICON from "../../images/icons/homeIcon.png";
import Image from "../core/image";
import MENU from "../../images/icons/menuBar.png";
import { NavLink } from "react-router-dom";
import OTHERSICON from "../../images/icons/othersIcon.png";
import React, { useState } from "react";
import ToolTip from "./toolTip";

export function MainNavBar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const menuItems = [
    {
      name: "Home",
      to: "/home",
      icon: HOMEICON,
      title: "Home",
    },
    {
      name: "Calendar",
      to: "/calendar/*",
      icon: CALENDARICON,
      title: "Calendar",
    },
    {
      name: "Articles",
      to: "/articles/*",
      icon: ARTICLEICON,
      title: "Articles",
    },
    {
      name: "Samayal",
      to: "/samayal/*",
      icon: SAMAYALICON,
      title: "cooking ",
    },
    {
      name: "TamilBooks",
      to: "/tamilbooks/*",
      icon: BOOKSICON,
      title: "Books",
    },
    {
      name: "TNPSC",
      to: "/tnpsc/*",
      icon: TNPSCICON,
      title: "TNPSC",
    },
    {
      name: "Others",
      to: "/others/*",
      icon: OTHERSICON,
      title: "Others",
    },
  ];

  const activeLinkStyle = ({ isActive }) => {
    return {
      fontWeight: isActive ? "bold" : "normal",
    };
  };
  return (
    <div
      className={`flex sm:justify-center left-0 right-0 sm:py-2 pb-8 bg-red`}
    >
      <ButtonWithImage
        src={MENU}
        className="w-8 text-black bg-transparent absolute block sm:hidden"
        onclick={() => setNavbarOpen(!navbarOpen)}
      />
      <ul className="flex flex-col sm:flex-row sm:mt-0 mt-2 sm:ml-0 ml-[80px]">
        {menuItems.map((index, key) => {
          return (
            <div className={`sm:flex ${navbarOpen ? "flex" : "hidden"}`}  key={key}>
              <Image
                className="w-[40px] h-[40px] lg:ml-14 md:ml-5 sm:ml-3 mr-2 sm:visible invisible"
                src={index.icon}
              />
              <ToolTip title={index.title}>
                <li className="sm:mt-2 mt-4">
                  <NavLink
                    className="hover:bg-cyan-300"
                    style={activeLinkStyle}
                    to={index.to}
                    key={key}
                  >
                    {index.name}
                  </NavLink>
                </li>
              </ToolTip>
            </div>
          );
        })}
      </ul>
    </div>
  );
}
