import { ButtonWithImage } from "./buttonWithImage";
import Input from "../core/inputField";
import Image from "../core/image";
import React from "react";
import search from "../../images/icons/searchIcon.png";
import PLAYSTORE_ICON from "../../images/icons/playstoreIcon.png";
import KADALPURA_LOGO from "../../images/kadalpuraLogo.png";
import WHITURE_LOGO from "../../images/whitureLogo.png";
import { Link, useNavigate } from "react-router-dom";

export default function Banner() {
  const navigate = useNavigate();

  return (
    <div className="bg-primary flex h-[75px]">
      <Image className=" w-[180px] h-[78px]" src={WHITURE_LOGO} alt={"logo"} />
      {/* <div className="text-white mt-1 ml-[40%]">
        <p className="ml-7">சென்னை</p>
        <p className="ml-[40px]">24.99</p>
        <Link>Click here change the location </Link>
      </div> */}
      <div className="bg-white sm:flex w-[200px] h-[40px] mt-4 ml-[70px] relative rounded-lg focus:outline-none hidden">
        <ButtonWithImage src={search} className="mt-1 ml-1 w-6 h-8" />
        <Input
          className="rounded-lg h-8 mt-1 pl-2 text-sm focus:outline-none"
          type={"search"}
          placeholder={"Search"}
        />
      </div>
      <ButtonWithImage
        src={PLAYSTORE_ICON}
        className="w-[40px] h-8 mt-4 sm:ml-5"
        onclick={() => {
          navigate("/our-app");
        }}
      />
    </div>
  );
}
