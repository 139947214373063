import React from "react";

export default function Image({
  src="image",
  style: overriddenStyles = {},
  className: overriddenClasses = "",
}) {
  return (
      <img
        src={src}
        alt={"image"}
        style={{
          ...overriddenStyles,
        }}
        className={overriddenClasses}
      />
  );
}
