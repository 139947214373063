import React from "react";
import { useState, useEffect } from "react";
import Image from "../../../component/core/image";
import { ButtonWithImage } from "../../../component/widgets/buttonWithImage";
import Share from "../../../images/icons/shareIcon.png";
import Like from "../../../images/icons/likeIcon.png";
import LikeFilled from "../../../images/icons/likeFilledIcon.png";
import { useParams } from "react-router-dom";
import RelatedArticle from "./relatedArticle";
import GetDate from "../../../component/widgets/getDate";
import HorizontalLine from "../../../component/core/horizontalLine";
import { ARTICLE_URL } from "../../../constants";
import ChangeImage from "../../../component/widgets/changeImage";

// export function getDate(date) {
//   return date.split("", 10);
// }

export default function ViewMain() {
  const axios = require("axios");

  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [articleId, setArticleId] = useState([]);

  const loaddata = () => {
    axios.post("/articles/get", { id: id }).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    loaddata();
  }, [id]);

  if (loading) {
    return <h1>Loading...</h1>;
  }
  function handler() {
    localStorage.setItem("id", articleId);
  }
  return (
    <div className="bg-secondary grid md:grid-cols-3 gap-2 ">
      <div className="col-span-2">
        <p className="text-center font-bold">{data.article.title}</p>
        <div className="mt-4 flex justify-evenly">
          <GetDate date={data.article.createdAt} />
          <ChangeImage
            className="w-7"
            src1={Like}
            src2={LikeFilled}
            onclick={(e) => {
              setArticleId(e.target.value);
            }}
          />
          <ButtonWithImage
            className="w-5"
            src={Share}
            onclick={() => {
              // https://kadalpura.com/articles/articles-share-id=/
            }}
          />
        </div>
        <Image
          className="ml-5 my-4 w-90"
          src={`${ARTICLE_URL}${data.article.banner_url}`}
        />
        <div className="ml-3">
          {data.article.content.map((object) => {
            switch (Object.keys(object)[0]) {
              case "quote":
                return <p className="font-bold my-3">{object.quote}</p>;
              case "para":
                return <p className="mb-2">{object.para}</p>;
              case "img_url":
                return (
                  <Image
                    className="ml-5 my-6 w-90 "
                    src={`${ARTICLE_URL}${object.img_url}`}
                  />
                );
              default:
                break;
            }
          })}
        </div>
      </div>
      <div>
        <p className="font-bold mt-4">Related Articles</p>
        <HorizontalLine color={"grey"} />
        {data.article.content.map((index) => {
          switch (Object.keys(index)[0]) {
            case "relevance":
              return <RelatedArticle props={index.relevance} />;
          }
        })}
      </div>
    </div>
  );
}
