import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Image from "../../../component/core/image";
import { ARTICLE_URL } from "../../../constants";
import HorizontalLine from "../../../component/core/horizontalLine";

export default function HomeMain() {
  const [post, setPost] = useState([]);

  const getData = () => {
    axios.get("/articles/home").then((response) => {
      setPost(response.data.home);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {post.map((home, key) => {
        return (
          <div className="mt-5" key={key}>
            <Link
              className="sm:flex mb-5"
              to={`/articles/articles-share-id=/${home.id}`}
            >
              <Image
                className="sm:w-[256px] sm:h-[150px] w-[400px] h-[200px] p-2 sm:p-0 sm:mx-3"
                src={`${ARTICLE_URL}${home.image_url}`}
              />
              <div className="justify-between ml-5">
                <p className="font-bold right mb-2">{home.title}</p>
                {home.short_desc}
              </div>
            </Link>
            <HorizontalLine />
          </div>
        );
      })}
    </>
  );
}
