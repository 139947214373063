import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Banner from "./component/widgets/banner";
import Error from "./pages/error";
import { MainNavBar } from "./component/widgets/mainNavBar";
import Test from "./pages/test";
import { Suspense } from "react";
import SamayalMain from "./pages/samayal/samayalMain"
import CalendarMain from "./pages/calendar/calendarMain"
import ArticlesMain from  "./pages/articles/articlesMain"
import OurApps from "./pages/ourApps";
import HomeMain from "./pages/homeMain";
import TNPSCMain from "./pages/TNPSC/tnpscMain";
import TamilBooksMain from "./pages/tamilBooks/tamilBooksMain";
import OthersMain from "./pages/othersMain";

/**
 * handles top level routing,
 */

function Main() {
  return (
    <Router>
      <Banner/>
      <MainNavBar />
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route exact path="/" element={<OurApps />} />
          <Route path="/home" element={<HomeMain/>} />
          <Route path="/calendar/*" element={<CalendarMain />} />
          <Route path="/articles/*" element={<ArticlesMain />} />
          <Route path="/samayal/*" element={<SamayalMain />} />
          <Route path="/tnpsc/*" element={<TNPSCMain />} />
          <Route path="/tamilbooks/*" element={<TamilBooksMain/>} />
          <Route path="/others/*" element={<OthersMain/>} />
          <Route path="/our-app" element={<OurApps/>} />
          <Route path="/test" element={<Test />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default Main;
