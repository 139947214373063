import axios from "axios";
import React, { useEffect, useState } from "react";
import Image from "../component/core/image";
import { PROMOTION_URL } from "../constants";

export default function OurApps() {
  const [appList, setAppList] = useState([]);

  const AppListComponent = ({ icon, title, rating, link, subtitle }) => (
    <div className="bg-[#ECECEC] sm:ml-4 mx-3 ml-2 mt-5 p-2 sm:w-[350px] h-[100px] overflow-hidden rounded-lg">
      <button
        className="flex"
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://play.google.com/store/apps/details?id=" + link,
            "_blank"
          );
        }}
      >
        <Image className="w-[80px] rounded-lg" src={icon} />
        <div className="text-left ml-4 overflow-x-auto ...">
          <p className="text-ellipsis whitespace-nowrap">{title}</p>
          <p>{rating} Rating</p>
          <p>{subtitle}</p>
        </div>
      </button>
    </div>
  );

  // /samayal/json/samayal.json

  useEffect(() => {
    axios.get("/books/store/tamil/storev2.json").then((res) => {
      setAppList(res.data.promotions);
    });
  }, []);

  // console.log(appList);

  return (
    <>
      <p className="ml-5 mt-3 font-bold">எமது பிற ஆப்ஸ்</p>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-1">
        {appList.map((index) => {
          return (
            <AppListComponent
              link={index.id}
              title={index.title}
              icon={`${PROMOTION_URL}${index.img_url}`}
              rating={index.rating}
              subtitle={index.subtitle}
            />
          );
        })}
      </div>
    </>
  );
}
