import React, { useState } from "react";
import { ARTICLE_URL } from "../../../constants";
import Image from "../../../component/core/image";
import HorizontalLine from "../../../component/core/horizontalLine";

export default function Favourites({ title, id, articleImage, description }) {
  const [addItem, setItem] = useState([]);
  const value = localStorage.getItem("name");
  return (
    <>
      <p>{value}</p>

      {/* Your Favourite's section is empty. */}
      {/* <div>
        இன்று சர்வதேச மாணவர் தினம்!
        <Image src={`${ARTICLE_URL}${"images/students 2.png"}`} />
        <p>
          'மாணவர்கள்' இவர்கள் 'வருங்காலமெனும் கோட்டையின் தூண்கள்'.
          வருங்காலமெனும் காவியத்தின் கதாநாயகர்கள்.
        </p>
      </div> */}

      {/* <ChangeImage
        src1={"https://source.unsplash.com/LYK3ksSQyeo"}
        src2={
          "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png"
        }
        className="w-[100px] m-9"
      /> */}
    </>
  );
}
