import { ButtonWithImage } from "../../../component/widgets/buttonWithImage";
import { SAMAYAL_URL } from "../../../constants";
import { GetSuggestedRecipes } from "./suggestedRecipes";
import Image from "../../../component/core/image";
import Like from "../../../images/icons/likeIcon.png";
import React from "react";
import Share from "../../../images/icons/shareIcon.png";
import { useLocation } from "react-router-dom";


export default function ViewMain({ props }) {
  const locate = useLocation();
  const id = locate.state.id;

  //fetch the recipe detail from local json file based on the recipe clicked.
  const recipeData = props.find((recipe) => recipe.recipe_id === id);
  return (
    <div className="ml-5">
      <p className="text-center text-2xl font-semibold">{recipeData.title}</p>
      <Image
        className="rounded-2xl p-2 flex"
        src={`${SAMAYAL_URL}${recipeData.recipe_id}c.png`}
      />
      <p className="flex">
        <ButtonWithImage className="xs:w-6 w-8 my-3 ml-3" src={Like} />
        <ButtonWithImage className="xs:w-5 w-7 my-3 ml-3" src={Share} />
      </p>
      <p className="text-lg font-semibold">தேவையான பொருட்கள்</p>
      <ul className="m-5 list-disc">
        {recipeData.ingredients_ta.map((item, index) => (
          <li key={item.recipe_id}>
            {`${item} - ${recipeData.quantity[index]} ${recipeData.quantity_type[index]}`}
          </li>
        ))}
      </ul>
      <p className="text-lg font-semibold">செய்முறை</p>
      <ul className="m-5 list-disc">
        {recipeData.instructions.map((item) => (
          <li key={item.recipe_id}>{item}</li>
        ))}
      </ul>
      <p className="text-xl">மருத்துவ நன்மைகள்</p>
      <ul className="m-5 list-disc">
        <li>{recipeData.benefits}</li>
        <li>{recipeData.sub_title}</li>
      </ul>
      <p className="text-xl">இவற்றை முயற்சிக்கவும்</p>
      {GetSuggestedRecipes(props,recipeData.suggested_recipes)}
    </div>
  );
}
