import Image from "../core/image";
import React from "react";

export function ButtonWithImage({
  src = "Image",
  style: overriddenStyles = {},
  className: overriddenClasses = "",
  onclick
}) {
  return (
    <div>
      <button
        style={{
          ...overriddenStyles,
        }}
        className={overriddenClasses}
        onClick={onclick }
      >
        <Image src={src} />
      </button>
    </div>
  );
}
