import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import HomeMain from "./home/homeMain";
import ViewMain from "./view/viewMain";
import CategoriesMain from "./categories/categoriesMain";
import axios from "axios";

export default function SamayalMain() {
  const [recipesData, setRecipesData] = useState([]);

  // json fetch for recipes..
  useEffect(() => {
    axios.get("/samayal/json/samayal.json").then((res) => {
      setRecipesData(res.data);
    });
  }, []);
  return (
    <>
      <Routes>
        <Route path="/home" element={<HomeMain />} />
        <Route
          path="/categories"
          element={<CategoriesMain props={recipesData} />}
        />
        <Route path="/view" element={<ViewMain props={recipesData} />} />
        <Route path="/*" element={<HomeMain />} />
      </Routes>
    </>
  );
}
