import React from "react";

export default function Input({
  type,
  placeholder,
  onChange,
  style: overriddenStyles = {},
  className: overriddenClasses = "",
}) {
  return (
    <div>
      <input
        type={type}
        placeholder={placeholder}
        className={overriddenClasses}
        style={{
          ...overriddenStyles,
          display: "flex",
          justifyContent: "space-between",
          outline:"none",
          borderRadius:'18px',
        }}
        onChange={onChange}
      />
    </div>
  );
}
