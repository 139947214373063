import React, { useState } from "react";
import TodayData from "../../../Assets/Data/that-day.json";
import { current } from "../../../constants";

export default function TodaySpecial() {
  const [data, setData] = useState([]);
  const date = current.getDate() - 1;
  const month = current.getMonth();

  return (
    <div>
      <p className="bg-red w-[200px] ml-[400px] mb-4 text-center">வரலாற்றில் இன்று</p>
      {TodayData.map((monthData, index) => {
        if (index === month) {
          return monthData.map((dayData, index) => {
            if (index === date) {
              return dayData.map((data, i) => {
                return <li className="ml-3 mb-3 text-justify">{data}</li>;
              });
            } else {
              return null;
            }
          });
        } else {
          return null;
        }
        // return (
        //   <>
        //     <div>
        //       {index === month
        //         ? today
        //         : null}
        //     </div>
        //   </>
        // );
      })}
    </div>
  );
}
