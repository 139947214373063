import React from "react";
import Clock from "../../images/icons/clockIcon.png";
import Image from "../core/image";
export default function GetDate({ date }) {
  return (
    <div className="flex">
      <Image className="mb-2 w-6 mr-1" src={Clock} />
      {date.split("", 10)}
    </div>
  );
}
