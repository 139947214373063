import React from "react";
import { Route, Routes } from "react-router-dom";
import IndruMain from "./indru/indruMain";
import RasiPalanMain from "./rasi_palan/rasiPalanMain";
import PanchangamMain from "./panchangam/panchangamMain";
import CalendarNavBar from "./calendarNavBar";

export default function CalendarMain(props) {
  return (
    <div>
      <CalendarNavBar/>
      <Routes>
        <Route path="/indru" element={<IndruMain />} />
        <Route path="/panchangam" element={<PanchangamMain />} />
        <Route path="/rasi-palan" element={<RasiPalanMain />} />
        <Route path="/*" element={<IndruMain />} />
      </Routes>
    </div>
  );
}
