import React from "react";

export default function HorizontalLine() {
  return (
    <hr
      style={{
        color: "grey",
        backgroundColor: "grey",
        height: 1,
        marginTop: "5px",
        marginBottom: "5px"
      }}
    />
  );
}
