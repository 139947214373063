import React from "react";
import { NavLink } from "react-router-dom";
export default function CalendarNavBar() {
    const activeLinkStyle = ({ isActive }) => {
        return {
          fontWeight: isActive ? "bold" : "normal",
        };
      };
  return (
    <div className="bg-red flex flex-grow">
      <NavLink className="ml-10" style={activeLinkStyle} to="/calendar/indru">
        Indru
      </NavLink>
      <NavLink className="xs:ml-10 ml-2" style={activeLinkStyle} to="/calendar/panchangam">
        Panchangam
      </NavLink>
      <NavLink className="xs:ml-10 ml-2" style={activeLinkStyle} to="/calendar/rasi-palan">
        RasiPalan
      </NavLink>
    </div>
  );
}
