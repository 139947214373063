import axios from "axios";
import React, { useEffect, useState } from "react";

export default function CategoryMain() {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    axios.get("/articles/categories").then((res) => {
      setCategoryList(res.data.categories);
    });
  }, []);
  
  //   console.log(categoryList);

  return (
    <div>
      CategoryMain
      <div>
        {categoryList.map((list) => {
          return (
            <div key={list.id}>
              <p>{list.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
