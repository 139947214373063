import React from "react";
import panjangam from "../Assets/Data/panchangam.json";
import Weather from "../component/widgets/weather";
import TodaySpecial from "./calendar/indru/todaySpecial";
import MonthlyCalendar from "./calendar/monthlyCalendar";
import { HomeMainMobile } from "./samayal/home/homeMainMobile";
// import { useLocation } from "react-router-dom";

export default function Test() {
  return (
    <>
      <MonthlyCalendar />
      <Weather />
      <TodaySpecial />
      {/* <HomeMainMobile /> */}
    </>
  );

  // const handle = () => {
  //   localStorage.setItem("Title", "title");
  //   localStorage.setItem("Date", "date");
  //   localStorage.setItem("Image", "image");
  //   localStorage.setItem("SubTitle", "subTitle");
  // };
  // const remove = () => {
  //   localStorage.removeItem("Title");
  //   localStorage.removeItem("Date");
  //   localStorage.removeItem("Image");
  //   localStorage.removeItem("SubTitle");
  // };
  // return (
  //   <>
  //     {localStorage.getItem("Title") && (
  //       <div>
  //         <p>{localStorage.getItem("Title")}</p>
  //       </div>
  //     )}
  //     {localStorage.getItem("Date") && (
  //       <div>
  //         <p>{localStorage.getItem("Date")}</p>
  //       </div>
  //     )}
  //     {localStorage.getItem("Image") && (
  //       <div>
  //         <p>{localStorage.getItem("Image")}</p>
  //       </div>
  //     )}
  //     {localStorage.getItem("SubTitle") && (
  //       <div>
  //         <p>{localStorage.getItem("SubTitle")}</p>
  //       </div>
  //     )}
  //     <div>
  //       <button onClick={remove}>Remove</button>
  //     </div>
  //   </>
  // );
}

// // import React from "react";

// // export default function SkeletonProduct() {
// //   return (
// //     <div className="absolute left-0 overflow-hidden">
// //       <div type="thumbnail" />
// //       <div type="text-lg" />
// //       <div type="text-md" />
// //       <div type="text-sm" />
// //       <div className="opacity-1">
// //       </div>
// //     </div>
// //   );
// // }
