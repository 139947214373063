import React from "react";
import RasiPalan from "../../../Assets/Data/day-12-2022.json";

export default function DailyRasipalan() {
  const title = [
    "மேஷம்",
    "ரிஷபம்",
    "மிதுனம்",
    "கடகம்",
    "சிம்மம்",
    "கன்னி",
    "துலாம்",
    "விருச்சிகம்",
    "தனுசு",
    "மகரம்",
    "கும்பம்",
    "மீனம்",
  ];
  
  const ListComponent = ({ title, description }) => {
    return (
      <div className="border rounded-lg flex w-[230px] mb-3 p-3 bg-secondary">
        <p className="w-[100px]">{title}</p>-
        <p className="ml-4">{description}</p>
      </div>
    );
  };

  const current = new Date();
  const date = `${current.getDate()}`;

  // useEffect(() => {
  //   axios.get(url).then((res) => {
  //     console.log(res);
  //   });
  // });

  return (
    <>
      <div className="telative border mt-4 ml-2 mb-2 p-2 w-[250px] bg-primary rounded-lg">
        <p className="mb-2 text-center text-white">RasiPalan</p>
        {RasiPalan.map((rasi) =>
          rasi.enDate == date
            ? rasi.rasi.map((index, key) => {
                return (
                  <>
                    <ListComponent title={title[key]} description={index} />
                  </>
                );
              })
            : null
        )}
      </div>
    </>
  );
}
